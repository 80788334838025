import React from 'react';
import './AngelList.scss';

const AngelList = () => {
  return (
    <svg
      className="angel-list "
      version="1.1"
      viewBox="-10 -10 70 70"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlSpace="preserve"
      x="0px"
      y="0px"
    >
      <circle className="circle" cx="25" cy="25" r="32" />
      <g className="social-icon">
        <path
          d="M25.49,22v-.06c-.58-1.73-1.16-3.52-1.81-5.24-.39-1.09-.84-2.24-1.29-3.33a2.24,2.24,0,0,0-.65-.89.88.88,0,0,0-1.42.45,1.52,1.52,0,0,0-.06.76,6.24,6.24,0,0,0,.45,2.18c.77,2.11,1.48,4.21,2.26,6.32C23.75,21.66,24.65,22,25.49,22Z"
          transform="translate(-0.1 -0.1)"
        />
        <path
          d="M30.47,22.56c.9-2.43,1.74-4.86,2.52-7.35a4.11,4.11,0,0,0,.19-1.54.9.9,0,0,0-.77-.83,1.06,1.06,0,0,0-.85.39,5,5,0,0,0-.84,1.47c-1,2.42-1.8,4.92-2.64,7.41v.13c.71.12,1.48.25,2.32.44C30.4,22.62,30.47,22.62,30.47,22.56Z"
          transform="translate(-0.1 -0.1)"
        />
        <path
          d="M22.06,30.42a4.34,4.34,0,0,0,1.17.83.93.93,0,0,0,1.29-.9.84.84,0,0,0-.06-.38c-.2-.45-.33-.9-.52-1.41a16,16,0,0,0-2-3.51,3.11,3.11,0,0,0-1-.83,1.5,1.5,0,0,0-2,1.34v.06c.13.32.2.71.33,1A16.14,16.14,0,0,0,22.06,30.42Z"
          transform="translate(-0.1 -0.1)"
        />
        <path
          d="M25,.1A24.9,24.9,0,1,0,49.9,25,24.9,24.9,0,0,0,25,.1ZM35,28.88a11.53,11.53,0,0,1-.78,3.71A9.89,9.89,0,0,1,25.62,39a10.56,10.56,0,0,1-5.24-.77,9.47,9.47,0,0,1-5-5.17A7.1,7.1,0,0,1,15,30a3.08,3.08,0,0,1,2.33-2.62,2.25,2.25,0,0,0,.71-.07l-.39-1a2.26,2.26,0,0,1,.45-2.43,3,3,0,0,1,2-1.09,2,2,0,0,1,1.35.32,1.54,1.54,0,0,0,.26.13c-.06-.19-.13-.32-.19-.51-.65-2-1.36-4-2-5.94-.25-.84-.45-1.67-.64-2.5a5.57,5.57,0,0,1,.32-2.11A2.06,2.06,0,0,1,21.48,11a1.84,1.84,0,0,1,1.1.83,16.24,16.24,0,0,1,1.49,2.49,43.52,43.52,0,0,1,2.2,5.63c.12.38.25.7.38,1.08,0,.07.07.07.13.13.13-.38.33-.77.45-1.21a29.21,29.21,0,0,1,1.88-4.67,12.38,12.38,0,0,1,1.68-2.81c.06-.19.26-.32.39-.45a1.83,1.83,0,0,1,2.71.19,2.77,2.77,0,0,1,.58,2.11,20,20,0,0,1-.77,3.26c-.58,1.73-1.23,3.45-1.81,5.18,0,.06-.07.13-.07.19s.26.06.39.13a3.43,3.43,0,0,1,2,1.28A5,5,0,0,1,34.86,26,11,11,0,0,1,35,28.88Z"
          transform="translate(-0.1 -0.1)"
        />
        <path
          d="M27.75,27.86a10.56,10.56,0,0,1-2.91-.51c.26.77.59,1.53.91,2.36A8.25,8.25,0,0,1,27.75,27.86Z"
          transform="translate(-0.1 -0.1)"
        />
        <path
          d="M32.47,24.47c-.32-.12-.58-.25-.91-.38A25.3,25.3,0,0,0,25.43,23a4.42,4.42,0,0,0-1.3.13c-.32.13-.58.32-.58.7a2,2,0,0,0,.84,1.86,4.55,4.55,0,0,0,1.62.64,21.19,21.19,0,0,0,4,.25,4,4,0,0,1,.91.07c.32.06.39.31.45.63s-.13.39-.32.45a8.49,8.49,0,0,1-1.1.45,6.82,6.82,0,0,0-3.56,3,3.64,3.64,0,0,0-.32,3.26,3,3,0,0,1,.26.83,2,2,0,0,1,.06.9,1.94,1.94,0,0,1-1.35-1,4.76,4.76,0,0,1-.45-1.66,4.44,4.44,0,0,1-.07-1,1.84,1.84,0,0,1-1.29.13c0,.12.06.19.06.25a1.76,1.76,0,0,1-.77,1.92,2.13,2.13,0,0,1-1.75.25,4.41,4.41,0,0,1-2.45-1.72A3.35,3.35,0,0,1,17.61,32c-.2-.51-.2-.77.13-1.09.06.13.19.19.25.32.33.39.58.77.91,1.15a3.42,3.42,0,0,0,1.29,1.09,1.53,1.53,0,0,0,1.29,0,.66.66,0,0,0,.39-.83l-.19-.38a9.41,9.41,0,0,0-1.75-2.43,9.19,9.19,0,0,0-1.16-1.09.89.89,0,0,0-1.16-.06,2.67,2.67,0,0,0-1.17,1.59,3.2,3.2,0,0,0,.2,2.05,8,8,0,0,0,3.29,4.09,8.52,8.52,0,0,0,7.43,1,9,9,0,0,0,5.88-5.95,10.79,10.79,0,0,0,.52-3.83,5.64,5.64,0,0,0-.26-1.85A2.13,2.13,0,0,0,32.47,24.47Z"
          transform="translate(-0.1 -0.1)"
        />
      </g>
    </svg>
  );
};

export default AngelList;
