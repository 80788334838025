import React from 'react';
import './Github.scss';

const Github = () => {
  return (
    <svg
      className="github"
      version="1.1"
      viewBox="-10 -10 70 70"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlSpace="preserve"
      x="0px"
      y="0px"
    >
      <circle className="circle" cx="25" cy="25" r="32" />
      <g className="social-icon">
        <path d="M24.22,9.45a14.77,14.77,0,0,0-5,28.65,2,2,0,0,0,.12-.6V36.41c-3.91.52-5-2.43-5.12-2.82a3.62,3.62,0,0,0-1.42-1.93l-.07,0c-.31-.21-1-.68-.78-1.32s.92-.61,1.22-.61a3.78,3.78,0,0,1,2.81,1.86,3,3,0,0,0,3.44,1.61,5.28,5.28,0,0,1,.93-1.79c-3-.53-6.4-2.13-6.4-7.93a6.39,6.39,0,0,1,1.5-4.23,6.24,6.24,0,0,1,.26-4.13.56.56,0,0,1,.35-.32c.14,0,1.56-.42,4.46,1.5a15,15,0,0,1,7.48,0c2.92-1.92,4.31-1.56,4.47-1.5a.54.54,0,0,1,.34.32A6.24,6.24,0,0,1,33,19.21a6.42,6.42,0,0,1,1.49,4.23c0,5.88-3.46,7.45-6.5,8a6.48,6.48,0,0,1,1.11,3.21V37.5a2.18,2.18,0,0,0,.11.62,14.77,14.77,0,0,0-5-28.67Zm1.83,2.89a11.52,11.52,0,0,0-7.52,1.35.5.5,0,0,1-.26.08.55.55,0,0,1-.3-1,12.52,12.52,0,0,1,6.09-1.65,13.92,13.92,0,0,1,2.14.16.55.55,0,0,1,.45.63.56.56,0,0,1-.63.46h0Zm4.08.83a.56.56,0,0,1-.5.3.53.53,0,0,1-.24-.06,11,11,0,0,0-1.16-.5.56.56,0,0,1-.32-.71.58.58,0,0,1,.5-.35h.21c.43.17.85.35,1.26.56a.55.55,0,0,1,.26.74Z" />
        <path d="M28.05,34.61a5.52,5.52,0,0,0-1.41-3.22.55.55,0,0,1-.16-.58.52.52,0,0,1,.46-.38c3.22-.36,6.5-1.47,6.5-7A5.37,5.37,0,0,0,32,19.68a.56.56,0,0,1-.11-.58,4.77,4.77,0,0,0,0-3.33,7.12,7.12,0,0,0-3.51,1.51.51.51,0,0,1-.45.07,14.17,14.17,0,0,0-7.37,0,.54.54,0,0,1-.46-.07,7,7,0,0,0-3.5-1.51,4.83,4.83,0,0,0,0,3.33.56.56,0,0,1-.1.58A5.35,5.35,0,0,0,15,23.44c0,5.54,3.28,6.66,6.49,7a.58.58,0,0,1,.46.38.57.57,0,0,1-.16.58,4.79,4.79,0,0,0-1.41,2.16.53.53,0,0,1-.32.43A4,4,0,0,1,15,32a3,3,0,0,0-1.4-1.2,5.14,5.14,0,0,1,1.63,2.34v.06c0,.07.84,2.71,4.52,1.93a.51.51,0,0,1,.46.1.54.54,0,0,1,.2.43V37.5a3.86,3.86,0,0,1-.16,1,14.53,14.53,0,0,0,4,.53,14.81,14.81,0,0,0,4-.54,3.36,3.36,0,0,1-.15-.94Zm1.23-8.47a.66.66,0,0,1,.2-.06.53.53,0,0,1,.54.26l.55.95a.55.55,0,0,1-.17.76l0,0a.59.59,0,0,1-.29.07.52.52,0,0,1-.46-.27l-.55-.95a.55.55,0,0,1,.17-.76Zm-9.92.78-.55.95a.52.52,0,0,1-.47.27.58.58,0,0,1-.28-.07.57.57,0,0,1-.21-.76l0,0,.54-.95a.54.54,0,0,1,.54-.26.83.83,0,0,1,.21.06.55.55,0,0,1,.2.75Zm2.62.94-.28,1.05a.53.53,0,0,1-.52.39H21a.52.52,0,0,1-.38-.65h0l.28-1.06a.56.56,0,0,1,.45-.4.41.41,0,0,1,.22,0,.54.54,0,0,1,.38.65Zm2.79,1.28a.55.55,0,1,1-1.1,0V28.05a.55.55,0,0,1,.55-.55.55.55,0,0,1,.55.55Zm2.07-2a.38.38,0,0,1,.21,0,.55.55,0,0,1,.46.39l.28,1.07a.54.54,0,0,1-.38.66h-.15a.55.55,0,0,1-.53-.4l-.28-1.05A.54.54,0,0,1,26.84,27.16Z" />
        <path d="M25,0A25,25,0,1,0,50,25,25,25,0,0,0,25,0Zm-.78,40.08a15.82,15.82,0,0,1-4.92-.78.64.64,0,0,1-.3-.11,15.84,15.84,0,1,1,5.22.89Z" />
      </g>
    </svg>
  );
};

export default Github;
