import React from 'react';
import './Prototype.scss';

const Prototype = () => {
  return (
    <svg
      version="1.1"
      className="prototype"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 586 570"
      xmlSpace="preserve"
    >
      <g>
        <path
          className="blueprint-roll"
          d="M7,481V76S14.36,8,80.68,8,152,71,152,71V494.12"
          transform="translate(1.5 0.5)"
        />
        <path
          className="blueprint-outline"
          d="M152,489.12c0-48-27-78.12-71.32-78.12C50.31,411,7,432,7,481s29,80,77,80H574a2.05,2.05,0,0,0,2-2V151.05a2.05,2.05,0,0,0-2-2.05H154.05a2.05,2.05,0,0,0-2.05,2.05V489.12c0,16.66-12.36,30.74-26.8,36.84-20.23,8.54-44.52,4.26-44.52-5.24V420"
          transform="translate(1.5 0.5)"
        />
        <polyline
          className="maze-exterior-one"
          points="159.5 215.5 501.5 215.5 501.5 494.63 343.5 494.63"
        />
        <line
          className="maze-exterior-two"
          x1="300.5"
          y1="494.63"
          x2="160.5"
          y2="494.63"
        />
        <polyline
          className="maze-interior-one"
          points="372.5 215.5 372.5 317.41 424.38 317.41"
        />
        <line
          className="maze-interior-two"
          x1="315.48"
          y1="317.41"
          x2="372.5"
          y2="317.41"
        />
        <line
          className="maze-interior-three"
          x1="441.26"
          y1="392.5"
          x2="489.5"
          y2="392.5"
        />
        <line
          className="maze-interior-four"
          x1="466.09"
          y1="317.41"
          x2="495.5"
          y2="317.41"
        />
        <polyline
          className="maze-interior-five"
          points="391.5 392.5 361.5 392.5 360.5 494.63"
        />
        <polyline
          className="maze-interior-six"
          points="245.5 392.5 279.5 392.5 279.5 494.63"
        />
        <line
          className="maze-interior-seven"
          x1="209.5"
          y1="392.5"
          x2="159.5"
          y2="392.5"
        />
        <line
          className="maze-interior-eight"
          x1="265.5"
          y1="317.41"
          x2="153.5"
          y2="317.41"
        />
      </g>
    </svg>
  );
};

export default Prototype;
