import React from 'react';

import About from '../About';
import Contact from '../Contact';
import Hero from '../Hero';
import Work from '../Work';

import './App.scss';

const App = () => {
  return (
    <main className="app">
      <Hero />
      <About />
      <Work />
      <Contact />
    </main>
  );
};

export default App;
