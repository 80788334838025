import React from 'react';
import './Development.scss';

const Development = () => {
  return (
    <svg
      version="1.1"
      className="development"
      x="0px"
      y="0px"
      viewBox="0 0 584.2 569.5"
      xmlSpace="preserve"
    >
      <path
        className="computer-outline"
        d="M324,243V33.92A24.92,24.92,0,0,1,348.92,9H551.48a24.93,24.93,0,0,1,24.93,24.92V535.78a24.93,24.93,0,0,1-24.93,24.92H348.92A24.92,24.92,0,0,1,324,535.78V519"
        transform="translate(-0.92 -0.5)"
      />
      <rect
        className="monitor-outline"
        x="8.5"
        y="247.09"
        width="379.61"
        height="266.41"
        rx="15.82"
      />
      <line
        className="monitor-stand"
        x1="133.08"
        y1="560.2"
        x2="261.28"
        y2="560.2"
      />
      <line
        className="computer-line-one"
        x1="388.72"
        y1="93.1"
        x2="506.32"
        y2="93.1"
      />
      <line
        className="computer-line-two"
        x1="388.72"
        y1="131.25"
        x2="506.32"
        y2="131.25"
      />
      <line
        className="computer-line-three"
        x1="388.72"
        y1="169.4"
        x2="506.32"
        y2="169.4"
      />
      <circle
        className="computer-power-button"
        cx="493.71"
        cy="278.93"
        r="19.63"
      />
      <polyline
        className="code-bracket-left"
        points="151.33 337.66 110.61 378.73 150.73 419.79"
      />
      <polyline
        className="code-bracket-right"
        points="241.6 419.79 282.32 378.73 242.19 337.66"
      />
      <line
        className="code-dash"
        x1="180.55"
        y1="428.67"
        x2="215.05"
        y2="328.78"
      />
    </svg>
  );
};

export default Development;
