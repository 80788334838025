import * as React from 'react';

import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import App from '../components/App';

import './styles.scss';

interface IndexPage {
  data: {
    site: {
      siteMetadata: {
        keywords: string;
        locale: string;
        name: string;
        description: string;
        tagline: string;
        type: string;
        url: string;
      };
    };
  };
}

export const indexPageQuery = graphql`
  query IndexPageQuery {
    site {
      siteMetadata {
        keywords
        locale
        description
        name
        tagline
        type
        url
      }
    }
  }
`;

const IndexPage: React.FC<IndexPage> = ({ data }) => {
  const {
    description,
    keywords,
    locale,
    name,
    tagline,
    type,
    url,
  } = data.site.siteMetadata;

  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {name} {tagline}
        </title>
        <meta name="keywords" content={keywords} />
        <meta name="type" property="og:type" content={type} />
        <meta
          name="title"
          property="og:title"
          content={`${name}, ${tagline}`}
        />
        <meta
          name="description"
          property="og:description"
          content={description}
        />
        <meta name="tagline" property="og:tagline:" content={tagline} />
        <meta name="locale" property="og:locate" content={locale} />
        <meta
          name="image"
          property="og:image"
          content="./jeremiahstanley.png"
        />
        <meta
          name="site=name"
          property="og:site_name"
          content={`${name} ${tagline}`}
        />
        <meta name="url" property="og:url" content={url} />
        <link rel="canonical" href={url} />
      </Helmet>
      <App />
    </React.Fragment>
  );
};

export default IndexPage;
