import React from 'react';

import './Laptop.scss';

const Laptop = ({ screenShot }: { screenShot: string }) => {
  return (
    <svg
      className="laptop"
      version="1.1"
      viewBox="0 0 830 500"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlSpace="preserve"
      x="0px"
      y="0px"
    >
      <defs>
        <clipPath id="screen-path">
          <path d="M142.53,367.15l2.4-321.6s210,2.08,235.12,1.25c32.67-1.06,44.3-4.1,44.3-4.1s26.6,2.47,44.25,2.51S696,46.59,695.8,46.87s5.36-.43,5.14,9.88-1.6,310.94-1.6,310.94Z" />
        </clipPath>
      </defs>
      <g className="screen">
        <image
          className="screen--image"
          width="558"
          transform="scale(1) translate(145 45)"
          href={screenShot}
        />
      </g>
      <path
        className="outline"
        d="M813.48,424c4.27,63.47-41.07,61.34-50.94,33.6-6.67-19.52-22.7-26.91-39.57-29.28s-489.49,2.93-577.37,1.15c-23.74,0-45.47.4-45.47-7.33-.89-17.5,328.66-5.42,389.78-6.23s206,5.8,206-13.77c0-13.66-121.06-8.47-274.14-3.64-66.4,2.09-260.25,5.4-265.08,5.29-16.91-.38-16.17-4.95-13.74-7.08s4.7.21,4.67,2.34-.93,4.33-3.44,4.67c-2.16.3-5.74-.05-11.75-2.65-14.8-6.4-13.6-41.6-13.6-41.6s4.8-268.81,2.4-308c0-27.6,18.69-30.41,34.4-30.41,20.4,0,519.21.81,545.61,0S726,49.81,726,49.81l-4,332.33,94,57.68c8.8,8.2-29.6,6.4-29.6,6.4s-724.82-.4-748.82,0-23.49-1.55-11.09-8.75S117.2,385,136.4,385c27.2.8,585.61-2.88,585.61-2.88l94.27,57s24.19,12.45,3.13,13.25-800.26.57-807.1.22c-7.92-.4-10.85-.67,19.55-18.27s110.67-67.2,110.67-67.2l2.4-321.6c12.06-3.74,286.75,10.21,284-6.67,0-7.47-9-6.47-9.54-1.27-.37,3.77,3,5.11,9.09,5.73,39.67,4,191.41,3.45,245.82,3,12-.1,26.68-3.3,26.63,10.44,0,12.55-1.6,310.94-1.6,310.94l-554.68-.27"
      />
    </svg>
  );
};

export default Laptop;
