import React from 'react';

import Fade from 'react-reveal/Fade';

import Laptop from '../../SVG/Mockups/Laptop';
import Phone from '../../SVG/Mockups/Phone';
import PhoneAlt from '../../SVG/Mockups/PhoneAlt';
import Tablet from '../../SVG/Mockups/Tablet';

import './Projects.scss';

const components = {
  Laptop,
  Phone,
  PhoneAlt,
  Tablet,
};

const Projects = ({ project }: { project: Project }) => {
  const {
    company,
    formFactor,
    screenShot,
    description: { title, tenure, responsibilities },
  } = project;

  const renderResponsibilities = () =>
    responsibilities.map((responsibility: string, index: number) => (
      <li className="project--ul--li" key={`${company}-${index}`}>
        {responsibility}
      </li>
    ));

  const TagName = components[formFactor];

  return (
    <article className="project">
      <Fade top>
        <TagName screenShot={screenShot} />
      </Fade>
      <Fade top>
        <h4 className="project--header">{company}</h4>
      </Fade>
      <Fade bottom>
        <p className="project--text">{title}</p>
      </Fade>
      <Fade bottom>
        <p className="project--text--tenure">{tenure}</p>
      </Fade>
      <Fade bottom cascade>
        <ul className="project--ul">{renderResponsibilities()}</ul>
      </Fade>
    </article>
  );
};

interface Description {
  title: string;
  tenure: string;
  responsibilities: string[];
}

interface Project {
  company: string;
  formFactor: keyof typeof components;
  screenShot: string;
  description: Description;
}

export default Projects;
