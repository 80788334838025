import React, { useState } from 'react';

import './Tooltip.scss';

const Tooltip = ({ title, children }: { title: string; children: any }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [className, setClassName] = useState('');

  const handleClick = () => {
    setClassName('');
    setIsVisible(true);
    setTimeout(() => {
      setClassName('fade-out');
      deleteElement();
    }, 3000);
  };

  const deleteElement = () => {
    setTimeout(() => {
      setIsVisible(false);
    }, 500);
  };

  return (
    <div
      className="tooltip"
      onClick={handleClick}
      onKeyDown={handleClick}
      role="button"
      aria-label="Close tooltip"
      tabIndex={0}
    >
      <div>{children}</div>
      {!!isVisible && (
        <div className={`tooltip--content ${className}`}>
          <span className="tooltip--content--arrow" />
          {title}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
