import React from 'react';

import './PhoneAlt.scss';

const PhoneAlt = ({ screenShot }: { screenShot: string }) => {
  return (
    <svg
      className="phone-alt"
      version="1.1"
      viewBox="-50 0 500 500"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlSpace="preserve"
      x="0px"
      y="0px"
    >
      <defs>
        <clipPath id="screen-path" transform="translate(37.04 7.28)">
          <path d="M96.08,394.08H274c0-10.84,7.81-28.58,17.42-35.17-19.15-12.64-17.42-35.85-7.83-52.12,0,0-2.87-26.43-3.7-39-.13-1.86,2.86-9.84,2.92-18.66-6.8-53.51-1.37-199.64-2.58-200H86V209.89s6.21,24.82,4.54,39.2l-6.12,14.66V373.52s4.09,7.44,4.84,10.42,1.49,10.14,1.49,10.14Z" />
        </clipPath>
      </defs>
      <g className="screen">
        <image
          className="screen--image"
          width="208"
          transform="translate(117 58)"
          href={screenShot}
        />
      </g>
      <path
        className="outline"
        d="M238.62,492.72c3.77-11,14.74-14.4,23.31-21.6s13.37-16.46,21.95-25,29.14-20.92,36-24.69,21.26-41.49,2.06-46.63c-50-16.55-58.14,41.48-15.78,37.71,28.08,3.27,23.37-39,3.43-35.66-4.8,1.38-23.31-.68-23.31-5.14s13.71-13,20.91-14.4c21.76-5.73,33.63-38.14,8.23-50.41-10.28-4.45-33.26-2.05-34.29,21.26s7.55,24.35,17.49,24.35c26.22-1,39.65-41.39,3.09-39.78-13,1-15.09-8.91-15.09-8.91a229.24,229.24,0,0,1-2.4-40.8c.68-22.64,29.83-3.78,31.61,10.28s-13.78,27.43-20.64,27.43S289,280.47,288,260.59s-9.94-57.27-1.71-69.61,28.11-1.37,29.48,10.63-19.88,31.89-25,30.86-7.2-32.58-7.2-32.58,1.72-131.32,1.72-162.18S263.3,6.49,263.3,6.49C14.86,12.94,94.76-50.17,81.23,276.7c0,0,1.72,119,.34,137.5s20.92,19.89,20.92,19.89,131.32,9.6,155.67,2.05,27.43-23.31,20.92-23.31-4.8,8.23-24,13-138.18-.69-154.64-1.38S86.37,395.34,86.2,387.11s-12.51-22.29-15.26-51.78,3.57-57.13,3.57-57.13C77,264.68,86.67,261.7,86.18,242c-1.18-47.88-29-45.83-30.71-41.77s-8,24.71-9.83,34.4c-2.92,15.81,11.06,2.46,14.74-9.22,2.46-7.77,4.39-20.67-.75-20-2.74.37-5.8,18.74-8.46,25.51C45.68,244.81,36.33,259,29.05,269s-8,34.4-14.74,47.92c-19.07,31-4.25,96-5.25,119.23,1,5.41,7.46,40.26,9.42,56.59"
        transform="translate(37.04 7.28)"
      />
    </svg>
  );
};

export default PhoneAlt;
