import React from 'react';
import './Twitter.scss';

const Twitter = () => {
  return (
    <svg
      className="twitter"
      version="1.1"
      viewBox="-10 -10 70 70"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlSpace="preserve"
      x="0px"
      y="0px"
    >
      <circle className="circle" cx="25" cy="25" r="32" />
      <g className="social-icon">
        <path
          d="M25,0C11.2,0,0,11.2,0,25s11.2,25,25,25s25-11.2,25-25S38.8,0,25,0z M37.3,21.7c0,4.5-1.8,9.1-4.9,12.4
		c-2.3,2.4-6.3,5.2-12.6,5.2c-8.5,0-12.4-5.1-12.4-5.1c0.2,0.3,8,0.2,8.9-0.7c-1.6-0.8-3.5-3.4-4.1-5.1l0.5,0.1c0,0-1.6-1.7-1.9-5.2
		v-2l0.4,0.2c-0.6-1.8-0.4-3.9,0.6-5.7l0.9-1.6l1.1,1.4c2.4,3,5.7,4.9,9.4,5.4c0-1.5,0.6-3,1.5-4.3c1.3-1.7,3.3-2.6,5.5-2.6
		c1.7,0,3.3,0.6,4.6,1.7c2.6-0.7,5.2-2.3,5.2-2.3l-1.1,3.6c0.8,0,3.1-1.3,3.1-1.3S40.7,19,37.3,21.7z"
        />
        <path
          d="M38.1,15.8c-1.1,0.7-2.3,1.1-3.6,1.4c-1-1.1-2.5-1.8-4.2-1.8c-3.7,0-6.4,3.4-5.6,7c-4.7-0.2-9-2.5-11.8-6
		c-1.5,2.6-0.8,5.9,1.8,7.6c-0.9,0-1.8-0.3-2.6-0.7c0,2.7,1.9,5.2,4.7,5.7c-0.5,0.1-1,0.2-1.6,0.2c-0.3,0-0.7,0-1-0.1
		c0.7,2.3,2.8,3.9,5.3,4c-2.1,1.6-4.6,2.5-7.2,2.5c-0.4,0-0.8,0-1.3-0.1c2.5,1.6,5.5,2.6,8.8,2.6c10.6,0,16.6-9,16.2-17
		c1.2-0.9,2.2-1.9,2.9-3c-1,0.5-2.1,0.8-3.3,0.9C36.8,18.3,37.7,17.2,38.1,15.8z"
        />
        <path d="M12.4,34.9c-0.1,0-0.1,0-0.2,0c0,0,0.1,0,0.1,0C12.3,34.9,12.3,34.9,12.4,34.9z" />
      </g>
    </svg>
  );
};

export default Twitter;
