import React from 'react';
import './Email.scss';

const Email = () => {
  return (
    <svg
      className="email"
      version="1.1"
      viewBox="-10 -10 70 70"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlSpace="preserve"
      x="0px"
      y="0px"
    >
      <circle className="circle" cx="25" cy="25" r="32" />
      <g className="social-icon">
        <path d="M14,33.8l6-4.72-6-4.73Z" transform="translate(0 -0.08)" />
        <path
          d="M20.94,28.36l1.53-1.19a3.76,3.76,0,0,1,5.07,0l1.52,1.19L32.12,26V16.3H17.84v9.64l2.81,2.21ZM20.25,19h9.33a1.33,1.33,0,0,1,.56.06.49.49,0,0,1,.36.47.51.51,0,0,1-.36.53,1.76,1.76,0,0,1-.56.07H20.25c-.49,0-.79-.25-.75-.6S19.87,19,20.25,19Zm-.34,3.82a.49.49,0,0,1-.41-.54.52.52,0,0,1,.5-.51,1.69,1.69,0,0,1,.45,0H26a.55.55,0,0,1,.61.56c0,.38-.27.52-.6.56a5.42,5.42,0,0,1-.57,0h-5A3.07,3.07,0,0,1,19.91,22.83Z"
          transform="translate(0 -0.08)"
        />
        <path d="M36,33.81V24.34l-6,4.74Z" transform="translate(0 -0.08)" />
        <path
          d="M16.71,20.49,14,22.91l2.71,2.17Z"
          transform="translate(0 -0.08)"
        />
        <path
          d="M22.9,15.08h4.21C25.7,13.7,24.17,13.72,22.9,15.08Z"
          transform="translate(0 -0.08)"
        />
        <path
          d="M25,.08a25,25,0,1,0,25,25A25,25,0,0,0,25,.08ZM37.14,36.25c0,.83-.13,1-1,1H13.81c-.82,0-1-.14-1-1V23.08A1.27,1.27,0,0,1,13.3,22l3.39-3V16c0-.63.19-.81.83-.81H21l1.47-1.25a3.65,3.65,0,0,1,4.95,0l1.39,1.2h3.43c.79,0,.94.15.94.92V19c1.1,1,2.21,2,3.35,3a1.43,1.43,0,0,1,.53,1.18Q37.15,29.69,37.14,36.25Z"
          transform="translate(0 -0.08)"
        />
        <path
          d="M33.3,25.08,36,22.9l-2.7-2.41Z"
          transform="translate(0 -0.08)"
        />
        <path
          d="M23.2,28c-1.29,1-2.62,2-3.92,3.06-1.77,1.38-3.53,2.78-5.27,4.15v.86H36v-.85c-3.07-2.41-6.14-4.77-9.15-7.19A2.73,2.73,0,0,0,23.2,28Z"
          transform="translate(0 -0.08)"
        />
      </g>
    </svg>
  );
};

export default Email;
