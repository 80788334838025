import React from 'react';
import './Design.scss';

const Design = () => {
  return (
    <svg className="design" viewBox="0 0 573.36 570.3">
      <g>
        <rect
          className="ruler"
          x="343.86"
          y="8.5"
          width="221"
          height="553.3"
          rx="14.2"
        />
        <circle className="ruler-circle" cx="489.96" cy="77.05" r="37.22" />
        <line
          className="ruler-line-one"
          x1="351.86"
          y1="487.95"
          x2="406.47"
          y2="487.95"
        />
        <line
          className="ruler-line-two"
          x1="351.86"
          y1="429.06"
          x2="423.59"
          y2="429.06"
        />
        <line
          className="ruler-line-three"
          x1="351.86"
          y1="370.17"
          x2="406.47"
          y2="370.17"
        />
        <line
          className="ruler-line-four"
          x1="351.86"
          y1="311.27"
          x2="406.47"
          y2="311.27"
        />
        <line
          className="ruler-line-five"
          x1="351.86"
          y1="252.38"
          x2="423.59"
          y2="252.38"
        />
        <line
          className="ruler-line-six"
          x1="351.86"
          y1="193.49"
          x2="406.47"
          y2="193.49"
        />
        <line
          className="ruler-line-seven"
          x1="351.86"
          y1="134.6"
          x2="406.47"
          y2="134.6"
        />
        <line
          className="ruler-line-eight"
          x1="351.86"
          y1="75.7"
          x2="406.47"
          y2="75.7"
        />
      </g>
      <g>
        <path
          className="pencil-outline"
          d="M75.18,10.32C8.57,10.32,8.57,45,8.57,45V456.71L66.76,555a9.63,9.63,0,0,0,16.53.2l60.16-96.41L141.79,46.72S141.79,10.32,75.18,10.32Z"
          transform="translate(0)"
        />
        <line
          className="pencil-vertical-left"
          x1="41.68"
          y1="122.49"
          x2="41.68"
          y2="448.19"
        />
        <line
          className="pencil-vertical-center"
          x1="76.01"
          y1="122.49"
          x2="76.01"
          y2="448.19"
        />
        <line
          className="pencil-vertical-right"
          x1="110.33"
          y1="122.49"
          x2="110.33"
          y2="448.19"
        />
        <line
          className="pencil-horizontal-one"
          x1="15.79"
          y1="44.45"
          x2="134.81"
          y2="44.45"
        />
        <line
          className="pencil-horizontal-two"
          x1="10.66"
          y1="93.56"
          x2="136.8"
          y2="93.56"
        />
        <line
          className="pencil-horizontal-three"
          x1="7.18"
          y1="117.56"
          x2="140.29"
          y2="117.56"
        />
        <path
          className="pencil-horizontal-four"
          d="M143.52,439.67s0,17-16.6,17-16.59-17-16.59-17h-.07s0,17-17.16,17-17.16-17-17.16-17H76s0,17-17.17,17-17.16-17-17.16-17,0,17-16.59,17-16.59-17-16.59-17"
          transform="translate(0)"
        />
        <line
          className="pencil-tip"
          x1="61.76"
          y1="545.42"
          x2="89.14"
          y2="545.42"
        />
      </g>
    </svg>
  );
};

export default Design;
