import React, { useRef } from 'react';

import { OutboundLink } from 'gatsby-plugin-google-analytics';
import { useInView } from 'react-hook-inview';
import Lottie from 'react-lottie';

import Reveal from 'react-reveal/Reveal';
import Roll from 'react-reveal/Roll';
import Slide from 'react-reveal/Slide';

import Divider from '../SVG/Layout/Divider';
import Tooltip from '../Tooltip';

import AngelList from '../SVG/Social/AngelList';
import Email from '../SVG/Social/Email';
import Github from '../SVG/Social/Github';
import LinkedIn from '../SVG/Social/LinkedIn';
import Twitter from '../SVG/Social/Twitter';

import content from '../content.json';
import figureAnimation from './figure.json';

import './Contact.scss';

const Contact = () => {
  const [ref, isVisible] = useInView({ threshold: 0 });
  const textAreaRef = useRef();

  const figureOptions = {
    animationData: figureAnimation,
    autoplay: true,
    loop: false,
    rendererSettings: {
      preserveAspectRatio: 'xMinYMin meet',
    },
  };

  const copyEmail = () => {
    textAreaRef.current.select();
    document.execCommand('copy');
  };

  const renderAnimation = () => {
    return <Lottie options={figureOptions} />;
  };

  return (
    <section className="contact">
      <article className="contact--blurb">
        <Slide top>
          <h2 className="contact--blurb--header">{content.contactHeader}</h2>
        </Slide>
        <Reveal effect="growDivider">
          <Divider color="#e9e9e9" />
        </Reveal>
        <Reveal effect="fadeInUp">
          <p className="contact--blurb--text">{content.contactParagraph}</p>
        </Reveal>
        <div className="contact--blurb--social-icons">
          <Roll left>
            <OutboundLink
              aria-label="Angel List"
              className="contact--blurb--social-icons--icon"
              href="https://angel.co/u/jeremiah-stanley"
              target="_blank"
              rel="noopener noreferrer"
            >
              <AngelList />
            </OutboundLink>
          </Roll>
          <Roll left>
            <OutboundLink
              aria-label="Github"
              className="contact--blurb--social-icons--icon"
              href="https://github.com/jeremiahjstanley"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Github />
            </OutboundLink>
          </Roll>
          <Roll left>
            <Tooltip title="My email is copied to your clipboard!">
              <div
                className="contact--blurb--social-icons--icon"
                onClick={copyEmail}
                onKeyDown={copyEmail}
                role="button"
                aria-label="Copy email to clipboard"
                tabIndex={0}
              >
                <Email />
              </div>
            </Tooltip>
          </Roll>
          <Roll left>
            <OutboundLink
              aria-label="Linked In"
              className="contact--blurb--social-icons--icon"
              href="https://www.linkedin.com/in/jeremiahjstanley/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <LinkedIn />
            </OutboundLink>
          </Roll>
          <Roll left>
            <OutboundLink
              aria-label="Twitter"
              className="contact--blurb--social-icons--icon"
              href="https://twitter.com/MiahStanley"
              rel="noopener noreferrer"
              target="_blank"
            >
              <Twitter />
            </OutboundLink>
          </Roll>
        </div>
      </article>
      <div ref={ref} className="contact--animation">
        {isVisible && renderAnimation()}
      </div>
      <form className="contact--form" aria-hidden>
        <textarea
          aria-hidden
          ref={textAreaRef}
          value="jeremiah.j.stanley@gmail.com"
          readOnly
        />
      </form>
      <p className="contact--footer">
        ©TwentyTwentyTwo: Jeremiah Stanley - All Rights Reserved
      </p>
    </section>
  );
};

export default Contact;
