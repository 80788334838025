import React from 'react';
import Lottie from 'react-lottie';

import Reveal from 'react-reveal/Reveal';
import Slide from 'react-reveal/Slide';

import Divider from '../SVG/Layout/Divider';
import Design from '../SVG/Process/Design';
import Development from '../SVG/Process/Development';
import Prototype from '../SVG/Process/Prototype';

import content from '../content.json';
import continuousLineAnimation from './continuousLine.json';

import './About.scss';

const About = () => {
  const continuousLineOptions = {
    animationData: continuousLineAnimation,
    autoplay: true,
    loop: true,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid meet',
    },
  };

  const renderAnimation = () => {
    return <Lottie options={continuousLineOptions} />;
  };
  return (
    <section className="about">
      <Reveal effect="opacity">
        <div className="about--blob">
          <div className="about--blob--animation">{renderAnimation()}</div>
        </div>
      </Reveal>
      <article className="about--blurb">
        <Slide top>
          <h2 className="about--blurb--header">{content.introductionHeader}</h2>
        </Slide>
        <Reveal effect="growDivider">
          <Divider color="#000000" />
        </Reveal>
        <Reveal effect="fadeInUp">
          <p className="about--blurb--text">{content.introductionParagraph}</p>
        </Reveal>
        <ul className="about--blurb--ul">
          <li className="about--blurb--ul--li">
            <Reveal effect="drawIcon">
              <Design />
            </Reveal>
            <Slide bottom>
              <h3>design</h3>
            </Slide>
          </li>
          <li className="about--blurb--ul--li">
            <Reveal effect="drawIcon">
              <Prototype />
            </Reveal>
            <Slide bottom>
              <h3>prototype</h3>
            </Slide>
          </li>
          <li className="about--blurb--ul--li">
            <Reveal effect="drawIcon">
              <Development />
            </Reveal>
            <Slide bottom>
              <h3>develop</h3>
            </Slide>
          </li>
        </ul>
      </article>
    </section>
  );
};

export default About;
