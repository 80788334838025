import React, { useState } from 'react';
import Fade from 'react-reveal/Fade';
import Reveal from 'react-reveal/Reveal';

import Divider from '../SVG/Layout/Divider';
import Projects from './Projects';

import content from '../content.json';

import './Work.scss';

const Work = () => {
  const [selectedProject, setProject] = useState('');
  const [delayed, setDelayed] = useState(false);

  const selectProject = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    const project = event.currentTarget.dataset.arg || '';
    setDelayed(true);
    setTimeout(() => setDelayed(false), 1500);
    setProject(project);
    return;
  };

  const renderProjectHeader = () => {
    if (!delayed) {
      return (
        <Reveal effect="fadeInDown">
          <h2 className="work--blurb--header">{selectedProject}</h2>
        </Reveal>
      );
    }
  };

  const renderProject = () => {
    const correspondingProject = content.workHistory.find(
      ({ project }: { project: string }) => project.includes(selectedProject)
    );
    if (!delayed) {
      return <Projects project={correspondingProject} />;
    }
  };

  const pastProjects = () => {
    return content.workHistory.map(({ company, project }) => {
      return (
        <li className="work--list--item" key={project}>
          <button
            className="work--list--item--button"
            onClick={selectProject}
            data-arg={project}
            tabIndex={0}
            aria-label={`${company} ${project}`}
          >
            <h3 className="work--list--item--button--header">{company}</h3>
            <p className="work--list--item--button--text">{project}</p>
          </button>
        </li>
      );
    });
  };

  return (
    <section className="work">
      <article className="work--blurb">
        <div className="work--blurb--header">
          <Fade when={!selectedProject} collapse top>
            <h2 className="work--blurb--header">{content.workHeader}</h2>
          </Fade>
          {!!selectedProject && renderProjectHeader()}
        </div>
        <Reveal effect="growDivider">
          <Divider
            color="#000000"
            clearProject={setProject}
            showLoader={!!selectedProject}
          />
        </Reveal>
        {!!selectedProject && renderProject()}
        <div
          className={
            !!selectedProject ? 'work--blurb--remove' : 'work--blurb--render'
          }
        >
          <Reveal effect="fadeInUp">
            <p className="work--blurb--text">{content.workParagraph}</p>
          </Reveal>
          <Reveal effect="fadeInUp" cascade>
            <ul className="work--list">{pastProjects()}</ul>
          </Reveal>
        </div>
      </article>
    </section>
  );
};

export default Work;
