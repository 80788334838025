import React from 'react';

import Lottie from 'react-lottie';
import Reveal from 'react-reveal/Reveal';

import nameAnimation from './name.json';

import './Hero.scss';

const Hero = () => {
  const nameOptions = {
    animationData: nameAnimation,
    autoplay: true,
    loop: false,
    rendererSettings: {
      preserveAspectRatio: 'xMinYMin meet',
    },
  };
  return (
    <Reveal effect="opacity">
      <section className="hero">
        <div className="hero--animation">
          <Lottie options={nameOptions} />
        </div>
      </section>
    </Reveal>
  );
};

export default Hero;
