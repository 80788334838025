import React from 'react';

import './Tablet.scss';

const Tablet = ({ screenShot }: { screenShot: string }) => {
  return (
    <svg
      className="tablet"
      version="1.1"
      viewBox="0 0 900 500"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlSpace="preserve"
      x="0px"
      y="0px"
    >
      <defs>
        <clipPath id="screen-path">
          <path d="M156.06,237.93v.87l-3.5,8.74,3.5,12.23L158.68,279l1.75,22.72-1.75,84.75-.87,41.94s-.88,2.62,2.62,8.74,4.37,6.12,9.61,10.48,26.21,2.63,26.21,2.63l51.55.87,120.58,3.49,101.35.88,51.55-.88,111-.87h82.13s20.1,1.75,34.08-3.49c12.7-4.77,13.11-15.73,13.11-15.73l-.88-240.28S758.93,46.58,758.93,44s-14.85-3.5-14.85-3.5L648,39.59,462.74,37.84,307.21,37,184.89,36.1s-21,0-25.34,5.24-2.62,12.23-2.62,12.23v6.12l.25,128.23.26,27.29Z" />
        </clipPath>
      </defs>
      <g className="screen">
        <image
          className="screen--image"
          height="450"
          transform="translate(100 25)"
          href={screenShot}
        />
      </g>

      <path
        className="outline"
        d="M541.33,454.88l188.23-1.14c32.55,0,32.14-19,32.14-19s-2.42-367.4-2.42-382.93c0-10.61,0-10.61-18.4-11.36S204.46,34.8,176.15,36.22c-24.77,0-19.81,17-19.81,17s1.41,110.4,1.41,155.69-9.44,45.29-17.47,44.82c-8.8-3.2-9.68-17.06,2.71-16.66,29.71,6.2,10.58,168.46,15.31,191.27,0,12.1,9.12,23.06,27.74,22.22,65,0,231.07,8,314.23,4.62,104.74-4.25,77.84,17,239.19,18.4,45.49.4,48.13-17.7,48.13-33.27V45.71c0-30.33-12.74-30.65-46.71-29.31s-537.82.52-588.79,0c-40.71-.4-39.68,34-39.68,34s1.46,301.44,0,363.75,47.41,58,47.41,58H507.53c26.41,0,100.57.38,121.54,17.85"
      />
    </svg>
  );
};

export default Tablet;
