import React from 'react';

import './Phone.scss';

const Phone = ({ screenShot }: { screenShot: string }) => {
  return (
    <svg
      className="phone"
      version="1.1"
      viewBox="0 0 300 500"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlSpace="preserve"
      x="0px"
      y="0px"
    >
      <defs>
        <clipPath id="screen-path">
          <path d="M64.66,35.16l-1,254.6s2,17.59,2.69,22.55a385.26,385.26,0,0,1,3,41.73c-.28,11.52-4.56,28.56,0,34.49s5.51,8.21,15,8.56,146.51-2.56,146.51-2.56,8.9.63,9.45-13.24,3-196,3-196V41.3s0-17.73-12.28-17.73h-38s-1.24,9.18-9.31,9.59S141.58,35,132.68,33s-15.94-2.89-16.63-9.65H75.15S65.63,25.64,64.66,35.16Z" />
        </clipPath>
      </defs>
      <g className="screen">
        <image
          className="screen--image"
          width="200"
          height="500"
          transform="scale(.9) translate(70 -20)"
          href={screenShot}
        />
      </g>
      <path
        className="outline"
        d="M350.3,492c23.18-16.62,44.35-121.61,5-169.13-4.07-32.7-4.35-59.12-56.56-77.05-61.6-104.09-40.7,124.57-43,160.92-.2,3.38-3.38,25.94-21.38,25.94s-159.75-.9-159.75-.9-22.76-3.38-22.76-24.55V313.35s2.48-11.8,12.76-11.8c22.76,123-55.46,92.29,166.37,93,8.62,0,9.11-6.34,9.31-10.48,3.66-76.57,3.66-346.13,3.66-346.13s-2.49-14.35-12.76-14.35H193.24s-1.1,10.49-14.35,10-62.7,4.76-62.7-10.2H78.94S63.7,20,63.7,44.47V289.69S48.46,299,43.49,309.21,23.28,322,23.28,322c-28.63,23-10.69,57.73-5.93,80.22C43.7,401.5,54.39,379,48.73,356c25.25-28.41,7.73-89.32-24.56-40.69-20-41.8-4.06-88.16,31.39-57.94,1.79-117.06.48-181.83,0-214.8C54.59-6,88,10.4,226,11.71c3.38,0,16.35,2.27,21.8,8.62a33,33,0,0,1,7.73,23.18c-.49,25,0,187.27,0,187.27-3.87,24.76,2.07,54.36,32.07,74.08,0,0-3,30.49,5,44.36s-10.69,23.66-10.69,23.66-110.71,80-87,99.32S221,440.82,221,440.82H79.84c-20.9-.21-48.22-4.55-40.21-13.18,40.9-38.42,10-59.11-33.39-27.52,0,0-5.24,3.38-4.07,23.18s7.73,53.18,32.08,68.22"
      />
    </svg>
  );
};

export default Phone;
