import React from 'react';
import './LinkedIn.scss';

const LinkedIn = () => {
  return (
    <svg
      className="linked-in"
      version="1.1"
      viewBox="-10 -10 70 70"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlSpace="preserve"
      x="0px"
      y="0px"
    >
      <circle className="circle" cx="25" cy="25" r="32" />
      <g className="social-icon">
        <g>
          <path
            d="M29.5,21.4c-0.1,0-0.1,0-0.1,0c-1.2,0-2.4,0.6-3,1.7l-0.1,0.2h-0.9v-1.7h-2.6v11.1h2.7v-5.5
			c-0.1-0.8,0.1-1.6,0.4-2.4c0.5-0.8,1.4-1.3,2.3-1.2c0.1,0,0.1,0,0.1,0c0.9,0,1.7,0.6,2.1,1.4c0.2,0.7,0.4,1.6,0.3,2.4v5.4h2.7
			v-6.1c0-1.8-0.2-3-0.7-3.8C32.1,21.8,31.1,21.4,29.5,21.4z"
          />
        </g>
        <g>
          <path
            d="M36,12.2H14.2c-0.6,0-1.1,0.5-1.1,1.1v21.8c0,0.3,0.1,0.6,0.3,0.8c0.2,0.2,0.5,0.3,0.8,0.3H36
			c0.6,0,1.1-0.5,1.2-1.1V13.3C37.2,12.8,36.6,12.3,36,12.2L36,12.2L36,12.2L36,12.2z M33.8,14.6c0.3,0,0.6,0.2,0.6,0.6
			c0,0.3-0.2,0.6-0.6,0.6c-0.3,0-0.6-0.2-0.6-0.6C33.2,14.8,33.5,14.6,33.8,14.6z M32.1,14.6c0.3,0,0.6,0.2,0.6,0.6
			c0,0.3-0.2,0.6-0.6,0.6c-0.3,0-0.6-0.2-0.6-0.6C31.4,14.8,31.7,14.6,32.1,14.6z M30.3,14.6c0.3,0,0.6,0.2,0.6,0.6
			c0,0.3-0.2,0.6-0.6,0.6c-0.4,0-0.6-0.2-0.6-0.6C29.7,14.8,30,14.6,30.3,14.6z M28.6,14.6c0.3,0,0.6,0.2,0.6,0.6
			c0,0.3-0.2,0.6-0.6,0.6c-0.3,0-0.6-0.2-0.6-0.6C28,14.8,28.3,14.6,28.6,14.6z M26.8,14.6c0.3,0,0.6,0.2,0.6,0.6
			c0,0.3-0.2,0.6-0.6,0.6c-0.3,0-0.6-0.2-0.6-0.6C26.3,14.8,26.5,14.6,26.8,14.6z M25.1,14.6c0.3,0,0.6,0.2,0.6,0.6
			c0,0.3-0.2,0.6-0.6,0.6c-0.3,0-0.6-0.2-0.6-0.6C24.5,14.8,24.8,14.6,25.1,14.6z M23.3,14.6c0.3,0,0.6,0.2,0.6,0.6
			c0,0.3-0.2,0.6-0.6,0.6c-0.3,0-0.6-0.2-0.6-0.6C22.7,14.8,23,14.6,23.3,14.6z M15.7,33.7V20.6h4.8v13.1H15.7z M18,20
			c-1.5,0-2.7-1.2-2.7-2.7s1.2-2.7,2.7-2.7l0,0c1.5,0,2.7,1.2,2.7,2.7C20.7,18.8,19.5,20,18,20z M34.5,33.7h-4.8v-6.4
			c0.1-0.7-0.1-1.3-0.2-1.9c-0.2-0.5-0.4-0.7-1.2-0.7c-0.1,0-0.1,0-0.2,0c-0.5,0-0.9,0.2-1.2,0.7c-0.2,0.6-0.4,1.2-0.3,1.9v6.5h-4.8
			V20.6h4.6v0.8c0.8-0.7,1.9-1.1,3.1-1.1l0,0c1.9,0,3.3,0.7,4.1,1.9s0.9,2.9,0.9,4.4L34.5,33.7L34.5,33.7z"
          />
        </g>
        <g>
          <rect x="16.7" y="21.6" width="2.7" height="11" />
        </g>
        <path
          d="M25.1,0C11.3,0,0.2,11.2,0.2,24.9s11.2,24.9,24.9,24.9S50,38.7,50,24.9S38.8,0,25.1,0z M38.2,35.2
		c0,1.2-1,2.2-2.2,2.2H14.2c-1.2,0-2.2-1-2.2-2.2V13.4c0-1.2,1-2.2,2.2-2.2H36c1.2,0,2.2,1,2.2,2.2V35.2z"
        />
        <g>
          <path d="M18,15.6c-0.9,0-1.7,0.7-1.7,1.7c0,0.9,0.7,1.7,1.7,1.7c0.9,0,1.7-0.7,1.7-1.7C19.7,16.4,19,15.6,18,15.6z" />
        </g>
      </g>
    </svg>
  );
};

export default LinkedIn;
